import { Controller } from "@hotwired/stimulus"
import { validateDate } from "../services/date_validator";
import { validateHour } from "../services/hour_validator";

export default class extends Controller {
  static targets = ["message"]

  connect(){
    const input = this.element.getElementsByTagName("input")[0];

    input.addEventListener("input", (event) => { this.validate(event) });
    input.addEventListener("blur", (event) => { this.validate(event) });
  }

  async validate(event) {
    var eventTarget = event.target.value;
    var eventType   = event.type
    var isValid     = true;
    var kind        = this.messageTarget.dataset.stimulusValidationKind

    switch (kind) {
      case "date":
        this.#isValid(await validateDate(eventTarget, eventType));
        break;
      case "hour":
        this.#isValid(await validateHour(eventTarget, eventType));
        break;
      default:
        this.#isValid(false);
        break;
    }
  }

  #isValid(isValid){
    if (isValid) {
      this.messageTarget.setAttribute("hidden", "");
    } else {
      this.messageTarget.removeAttribute("hidden");
    }
  }
}
