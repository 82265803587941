import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="value-copier"
export default class extends Controller {
  static targets = ["inputReference"];

  inputReferenceTargetConnected(target) {
    let copyStrategy = target.getAttribute("data-value-copier-strategy");

    if (!copyStrategy) {
      target.setAttribute("data-value-copier-strategy", "onInput");
      copyStrategy = target.getAttribute("data-value-copier-strategy");
    };

    if (copyStrategy == "onInput") {
      this.#onInput(target);
    } else if (copyStrategy == "onConnection") {
      this.#onConnection(target);
    };
  };

  #addEventToTarget(target, event_name, callback) {
    target.addEventListener(event_name, callback, false);
  };

  #onInput(target) {
    const inputReference = target.getAttribute("data-value-copier-id");

    this.#addEventToTarget(target, "change", function(event) {
      const destination = document.querySelector(`[data-value-copier-input-reference="${inputReference}"]`);
      destination.value = event.srcElement.value;
      destination.dispatchEvent(new Event("input", { bubbles:true }));
    });
  };

  #onConnection(target) {
    const inputReference = target.getAttribute("data-value-copier-id");
    const destination = document.querySelector(`[data-value-copier-input-reference="${inputReference}"]`);

    if (destination) {
      const value = target.getAttribute("data-value-copier-value");
      destination.value = value;
      destination.dispatchEvent(new Event("input", { bubbles: true }));
    };
  };
};
