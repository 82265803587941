export async function validateDate(dateString, eventType = null) {
    var dateParts = dateString.split("/");

    var day = +dateParts[0];
    var month = +dateParts[1];
    var year = +dateParts[2];

    var dateObject = new Date(year, month - 1, day);

    if (!dateString){
        return true;
    }

    if (dateParts[2] && dateParts[2].length < 4 && eventType == "blur") {
        return false;
    }

    if (dateObject == "Invalid Date" && eventType == "blur") {
        return false;
    }

    if (dateObject < new Date(1500, 1, 1) && eventType == "blur") {
        return false;
    }

    if (dateString.length < 10) {
        return true;
    }

    if (
        isNaN(dateObject.getTime()) ||
        dateObject.getDate() !== day ||
        dateObject.getMonth() !== month - 1 ||
        dateObject.getFullYear() !== year
    ) {
        return false;
    }

    return true;
}