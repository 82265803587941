import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="element_handler"
export default class extends Controller {
  static targets = ["container", "element"];

  append(event) {
    const lastElement  = this.elementTargets[this.elementTargets.length - 1];
    const cloneElement = lastElement.cloneNode(true);
    const cloneInputs  = cloneElement.querySelectorAll("INPUT, SELECT");

    cloneInputs.forEach((input) => {
      let regex   = "";
      let newId   = "";

      if (input.type == "checkbox") {
        input.checked = false;
      } else {
        input.value = "";
      };

      if (this.element.hasAttribute("data-element-handler-attribute")) {
        const attribute = this.element.getAttribute("data-element-handler-attribute");

        regex = new RegExp(`\\[${attribute}\\]\\[\\d+\\]`, "g");

        const idRegex = new RegExp(`_${attribute}_\\d+`, "g");
        newId = input.id.replace(idRegex, function(match, string) {
          return match.replace(/\d+/g, (m, n) => { return parseInt(m) + 1 });
        });
      } else {
        const keyword = input.getAttribute("data-element-handler-keyword");

        regex = new RegExp(`\\[${keyword}\\[\\d+\\]\\]`, "g");

        newId = input.id.replace(/\[(\d+)\]/g, function(match, number) {
          const newNumber = parseInt(number) + 1;
          return `[${newNumber}]`;
        });
      };

      const newName = input.name.replace(regex, function(match, string) {
        return match.replace(/\d+/g, (m, n) => { return parseInt(m) + 1 });
      });

      input.id   = newId;
      input.name = newName;
    });

    this.containerTarget.insertBefore(cloneElement, lastElement.nextSibling);
  };

  remove(event) {
    const elementToRemove = event.target.closest('[data-element-handler-target="element"]');
    if (this.elementTargets.length > 1) {
      elementToRemove.remove();
    } else {
      const subElements = elementToRemove.querySelectorAll('[data-element-handler-target="subElement"]');
      subElements.forEach((subElement) => {
        subElement.value = "";
      })
    }
  }
}
