export async function validateHour(hourString, eventType = null) {
  const regex = /^(\d{2}):(\d{2})(?::(\d{2}))?$/;
  const match = hourString.match(regex);

  if (!match) return true;

  const hours   = parseInt(match[1], 10);
  const minutes = parseInt(match[2], 10);
  const seconds = match[3] !== undefined ? parseInt(match[3], 10) : 0;

  if (hours < 0 || hours > 23) return false;

  if (minutes < 0 || minutes > 59) return false;

  if (seconds < 0 || seconds > 59) return false;

  return true;
}
