import { Controller } from "@hotwired/stimulus"
import { useMutation } from "stimulus-use"
import { TableColumn } from "./../services/tableColumn"

// Connects to data-controller="sum-table"
export default class extends Controller {
  table  = this.element.closest("table");
  head   = this.table.tHead;
  bodies = this.table.tBodies;

  connect() {
    useMutation(this, { element: this.table, subtree: true, childList: true });
  };

  mutate(entries) {
    for (const entry of entries) {
      if (entry.addedNodes[0].tagName != "TBODY") break;

      this.#indexes().forEach((index) => {
        let column = this.#createColumn(index);

        this.#updateSumElement(column);
      });
    };
  };

  #updateSumElement(column) {
    column.sumCells();

    this.element.cells[column.index].innerText = column.sum;
  };

  #summableHeadCells() {
    const headCells = this.head.rows[0].cells;

    return Array.from(headCells).filter(cell => cell.hasAttribute("data-sum-table-column"));
  };

  #indexes() {
    return this.#summableHeadCells().reduce((indexes, cell) => {
      indexes.push(cell.cellIndex);

      return indexes;
    }, []);
  };

  #createColumn(index) {
    const bodies = this.bodies;
    let column   = new TableColumn(index);

    for (let i = 0; i < bodies.length; i++) {
      const body = bodies[i];
      const row  = body.rows[0];

      column.addCell(row.cells[index]);
    };

    return column;
  };
};
